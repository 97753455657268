<template>
  <div>
    <v-tabs v-model="items" style="border-bottom: 1px solid lightgray">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
        tab.name
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="items" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <component v-bind:is="tab.component"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import utilFunc from "@/common/utilFunc.js";

import BaseComponent from "./component/base.vue";

export default {
  components: {
    BaseComponent,
  },
  setup(props, ctx) {
    const { router } = useRouter();
    const ShopRepository = repositoryFactory.get("shops");
    const items = ref();
    const tabs = [
      {
        index: 1,
        name: "基本設定",
        component: BaseComponent,
      },
    ];

    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      // storeを一旦更新する
      await ShopRepository.get_with_prop(currentShop.id)
        .then((response) => {
          if (response.data) {
            if (response.data.prop) {
              Object.keys(response.data.prop).forEach(function (key) {
                store.dispatch("shops/setProp", {
                  "id": response.data.prop[key].id,
                  "key": response.data.prop[key].key,
                  "value": response.data.prop[key].value,
                });
              });
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:settingShop/component/base.js/init ShopRepository.get_with_prop (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    return {
      init,
      items,
      tabs,
    };
  },
};
</script>
